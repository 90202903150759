import './App.css'
import './Instructions.css'
import logo from './digital-dogs.png'
import $ from 'jquery';
import ico1 from './demo-ico1.png'
import ico2 from './demo-ico2.png'
import ico3 from './demo-ico3.png'
import arrow from './arrow.svg'
import { useEffect, useState } from 'react'

// partners
import cryptowalkers from './assets/partners/cryptowalkers.png'
import fluffyPolarBears from './assets/partners/fluffy-polar-bears.png'
import theLongLost from './assets/partners/the-long-lost.png'
import weirdoGhostGang from './assets/partners/weirdo-ghost-gang.png'
import muttniks from './assets/partners/muttniks.png'
import lobstars from './assets/partners/lobstars.png'
import habibiz from './assets/partners/habibiz.png'
import xone from './assets/partners/xone.png'
import littleLemonFriends from './assets/partners/little-lemon-friends.png'

import Flavor from './Constants'
import axios from 'axios'

const ALCHEMY_API_KEY = process.env.REACT_APP_ALCHEMY_API_KEY
const ALCHEMY_BASE_URL = `https://eth-mainnet.alchemyapi.io/v2/${ALCHEMY_API_KEY}/getNFTs/`

// Laika API
const LAIKA_BASE_URL = process.env.REACT_APP_LAIKA_BASE_URL

// Whitelisted wallets
const WHITELISTED_WALLETS = process.env.REACT_APP_WHITELISTED_WALLETS.split(',')

const getContractAddressByFlavor = (flavor) => {
    switch (flavor) {
        case Flavor.CRYPTOWALKERS:
            return `contractAddresses[]=${process.env.REACT_APP_CRYPTOWALKERS_CONTRACT_ADDRESS}`
        case Flavor.FLUFFY_POLAR_BEARS:
            return `contractAddresses[]=${process.env.REACT_APP_FLUFFY_POLAR_BEARS_CONTRACT_ADDRESS}`
        case Flavor.THE_LONG_LOST:
            return `contractAddresses[]=${process.env.REACT_APP_THE_LONG_LOST_CONTRACT_ADDRESS}`
        case Flavor.WEIRDO_GHOST_GANG:
            return `contractAddresses[]=${process.env.REACT_APP_WEIRDO_GHOST_GANG_CONTRACT_ADDRESS}`
        case Flavor.MUTTNIKS:
            return `contractAddresses[]=${process.env.REACT_APP_MUTTNIKS_CONTRACT_ADDRESS}&contractAddresses[]=${process.env.REACT_APP_MUTTNIKS_STAR_CONTRACT_ADDRESS}`
        case Flavor.LOBSTARS:
            return `contractAddresses[]=${process.env.REACT_APP_LOBSTARS_CONTRACT_ADDRESS}`
        case Flavor.HABIBIZ:
            return `contractAddresses[]=${process.env.REACT_APP_HABIBIZ_CONTRACT_ADDRESS}`
        case Flavor.XONE:
            return `contractAddresses[]=${process.env.REACT_APP_XONE_CONTRACT_ADDRESS}`
        case Flavor.LITLE_LEMON_FRIENDS:
            return `contractAddresses[]=${process.env.REACT_APP_LITTLE_LEMON_FRIENDS_CONTRACT_ADDRESS}`
        default:
            return "";
    }
}

function Instructions(props) {

    const [chromium, setChromium] = useState(true)
    const [walletAddress, setWalletAddress] = useState("")
    const [checking, setChecking] = useState(false)
    const [forbidden, setForbidden] = useState(false)
    const [error, setError] = useState(false)
    const [heavyTraffic, setHeavyTraffic] = useState(false)

    useEffect(() => {
        setChromium(!!window.chrome)
    }, [])

    async function onLaunchDemo() {
        setChecking(true)
        try {
            const response = await axios.post(`${LAIKA_BASE_URL}/session`)
            props.setSession(response.data)
        } catch (e) {
            console.error(`Unable to create session.`, e)
            if (e.response.status === 418) {
                setHeavyTraffic(true)
            } else {
                setError(true)
            }
        }
        setChecking(false)
    }

    function accord(e) {
        $(e.currentTarget.children[1]).slideToggle()
        $(e.currentTarget).toggleClass('active')
    }

    function renderPartnerLogo() {
        switch (props.flavor) {
            case Flavor.CRYPTOWALKERS:
                return <img src={cryptowalkers} alt="Cryptowalkers" />
            case Flavor.FLUFFY_POLAR_BEARS:
                return <img src={fluffyPolarBears} alt="Fluffy Polar Bears" />
            case Flavor.THE_LONG_LOST:
                return <img src={theLongLost} alt="The Long Lost" />
            case Flavor.WEIRDO_GHOST_GANG:
                return <img src={weirdoGhostGang} alt="Weirdo Ghost Gang" />
            case Flavor.MUTTNIKS:
                return <img src={muttniks} alt="Muttniks" />
            case Flavor.LOBSTARS:
                return <img src={lobstars} alt="Lobstars" />
            case Flavor.HABIBIZ:
                return <img src={habibiz} alt="The Habibiz" />
            case Flavor.XONE:
                return <img src={xone} alt="XONE" />
            case Flavor.LITLE_LEMON_FRIENDS:
                return <img src={littleLemonFriends} alt="Little Lemon Friends" />
        }
    }

    async function onLoginWithMetaMaskClicked(e) {
        e.preventDefault()
        try {
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' })
            setWalletAddress(accounts[0])
        } catch (e) {
            console.log(e)
        }
    }

    function onGetNFTsResponse(response) {
        if (response.data.totalCount) {
            onLaunchDemo()
        } else {
            setForbidden(true)
        }
    }

    function onGetNFTsError() {
        setError(true)
    }

    useEffect(() => {
        if (!walletAddress) return

        // allow access for whitelisted wallets
        if (WHITELISTED_WALLETS.filter((wallet) => wallet.toLowerCase().includes(walletAddress.toLowerCase())).length > 0) {
            onLaunchDemo()
            return
        }

        // check if user owns required NFT
        async function checkNFTs() {
            setChecking(true)
            try {
                const contractAddress = getContractAddressByFlavor(props.flavor)
                const response = await axios.get(`${ALCHEMY_BASE_URL}?owner=${walletAddress}&${contractAddress}`)
                onGetNFTsResponse(response)
            } catch (error) {
                console.error("Unable to validate NFTs.", error)
                onGetNFTsError()
            }
            setChecking(false)
        }
        checkNFTs()

    }, [walletAddress])

    function renderLoginWithMetamaskButton() {
        if (window.ethereum) {
            return (
                <button
                    className='purpleButton'
                    onClick={onLoginWithMetaMaskClicked}
                >
                    Login with MetaMask
                </button>
            )
        } else {
            return (
                <a
                    className='purpleButton'
                    href="https://metamask.io"
                    target="_blank"
                    style={{
                        display: 'block'
                    }}
                >
                    Install MetaMask
                </a>
            )
        }
    }

    function renderDefaultButton() {
        return (
            <button
                onClick={onLaunchDemo}
                className="font-bold w-full"
                style={{
                    padding: '16px 24px',
                    backgroundColor: 'rgba(185, 44, 220, 1)',
                    borderRadius: '12px',
                }}
            >
                Access Demo
            </button>
        )
    }

    function renderButton() {
        switch (props.flavor) {
            case Flavor.DEFAULT:
                return renderDefaultButton()
            default:
                return renderLoginWithMetamaskButton()
        }
    }

    return (
        <div className='min-w-screen min-h-screen flex flex-col'>
            {chromium ?
                <div />
                :
                <div className='bg-red-500 p-2 text-center'>Your browser is not among the recommended choices for an optimal experience with Laika. We suggest you use one based on <a className='underline' href="https://www.google.com/chrome/" target="_blank">Chromium</a>.</div>
            }
            <div className='container flex flex-col background flex-1'>
                <div
                    className='flex flex-row justify-between items-center py-8'
                >
                    <img
                        src={logo}
                        alt="The Digital Pets Company&trade;"
                    />
                    <div className='hidden sm:block'>
                        <a className='border rounded-lg p-3' href='https://digitaldogs.ai'>Go to Homepage</a>
                    </div>
                </div>
                <div className='max-w-md space-y-8 py-8 sm:py-16'>
                    {renderPartnerLogo()}
                    <div className='text-4xl font-bold'>Meet Laika</div>
                    <div className=''>At the current stage, she can hear you but can't see you. Emotions and gestures detections is disabled.</div>
                    <div className='flex flex-col space-y-4'>
                        <div className='flex flex-row justify-start items-center space-x-3'>
                            <img src={ico1} alt="" style={{
                                width: '48px',
                                height: '48px',
                            }} />
                            <p className="text-sm">
                                Please turn on access to your microphone when we request it.
                            </p>
                        </div>
                        <div className='flex flex-row items-center justify-start space-x-3'>
                            <img src={ico2} alt="" style={{
                                width: '48px',
                                height: '48px',
                            }} />
                            <p className="text-sm">
                                Please call them from a quiet place and let’s keep this one-on-one for now.
                            </p>
                        </div>
                        <div className='flex flex-row justify-start items-center space-x-3'>
                            <img src={ico3} alt="" style={{
                                width: '48px',
                                height: '48px',
                            }} />
                            <p className="text-sm">
                                Use voice commands and feedbacks in order to communicate with a Digital Dog, just like you would do with a real-life dog.
                            </p>
                        </div>
                    </div>
                    <div
                        className="accord cursor"
                        onClick={accord}
                        style={{
                            width: '100%',
                            maxWidth: '500px',
                            flex: 1
                        }}
                    >
                        <div className="accord-head flex-r v-center just-space">
                            <p
                                className="text-xs"
                            >
                                By using this service you agree that we can collect some data about your conversation.
                            </p>
                            <img src={arrow} alt="" />
                        </div>

                        <p className="text-xs" style={{
                            marginTop: '32px',
                            paddingLeft: '16px',
                            color: 'rgba(255, 255, 255, 0.7)',
                            display: 'none'
                        }}>
                            You’re about to start a conversation with Laika. Please be aware she’s still under on going development.
                            <br />
                            <br />
                            Audio data will not be stored at the end of the session, but trascript may be used to help us improve Laika and our platform. By continuing, you confirm that you understand the conditions and agree to our <a target="_blank" href='https://old.thedigitaldogs.co/privacy' className='underline'>privacy policy</a>.
                        </p>
                    </div>
                    {
                        heavyTraffic ?
                            <div className='warningMessage'>&#128561; Too many people are trying to play with Laika right now. Please try again in a few minutes.</div>
                            :
                            error ?
                                <div className='warningMessage'>&#128561; We have a problem accessing our services. Please try again in a few minutes.</div>
                                :
                                forbidden ?
                                    <div className='warningMessage'>&#129300; You must own at least 1 NFT from the collection to get access to the demo.</div>
                                    :
                                    checking ?
                                        <div className='text-center'>&#128526; Please wait...</div>
                                        :
                                        renderButton()
                    }
                </div>
            </div>
        </div>
    )
}

export default Instructions
