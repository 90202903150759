import './UnityComponent.css'
import Unity, { UnityContext } from 'react-unity-webgl'
import { useEffect, useState } from 'react'
import { AudioConfig, IntentRecognizer, LanguageUnderstandingModel, SpeechConfig } from 'microsoft-cognitiveservices-speech-sdk'
import * as ml5 from 'ml5'
import { isMobile } from 'react-device-detect'
import logo from './logo.jpeg'
import axios from 'axios'

const LAIKA_BASE_URL = process.env.REACT_APP_LAIKA_BASE_URL

const unityContext = new UnityContext({
    codeUrl: process.env.REACT_APP_CODE_URL,
    dataUrl: process.env.REACT_APP_DATA_URL,
    frameworkUrl: process.env.REACT_APP_FRAMEWORK_URL,
    loaderUrl: process.env.REACT_APP_LOADER_URL
})

const sendTranscriptionToUnity = (transcription) => {
    console.debug(new Date() + " | Send transcription to unity. | transcription: " + transcription)
    unityContext.send(
        "WebContext",
        "SetTranscription",
        transcription
    )
}

const sendRecognizingToUnity = (isRecognizing) => {
    const asNumber = isRecognizing ? 1 : 0
    console.debug(new Date() + " | Send recognizing status to unity. | status: " + asNumber)
    unityContext.send(
        "WebContext",
        "SetRecognizing",
        asNumber
    )
}

const sendIntentToUnity = (intent) => {
    console.debug(new Date() + " | Send intent to unity. | intent: " + intent)
    unityContext.send(
        "Laika",
        "OnCommand",
        intent
    )
}

const sendWhistleToUnity = () => {
    console.debug(new Date() + " | Send whistle to unity.")
    unityContext.send(
        "Laika",
        "OnWhistle"
    )
}

const sendIsMobileToUnity = () => {
    console.debug(new Date() + " | Send isMobile to unity | isMobile: " + isMobile)
    unityContext.send(
        "Laika",
        "OnIsMobile",
        isMobile ? 1 : 0
    )
}

const sendFlavorToUnity = (flavor) => {
    console.debug(new Date() + " | send flavor to unity | flavor: " + flavor)
    unityContext.send(
        "FlavorController",
        "setFlavor",
        flavor
    )
}

function UnityComponent(props) {

    const [loaded, setLoaded] = useState(false)
    const [progression, setProgression] = useState(0)
    const [recognizer, setRecognizer] = useState(null)
    const [mute, setMute] = useState(false)
    const [error, setError] = useState(false)

    useEffect(() => {
        if (!props.session) return
        // refresh session every 30 seconds
        const sessionId = props.session.sessionId
        const subscriptionKey = props.session.subscriptionKey
        const interval = setInterval(() => {
            axios.put(
                `${LAIKA_BASE_URL}/session`,
                {
                    "sessionId": sessionId,
                    "subscriptionKey": subscriptionKey
                }
            )
                .then(() => {
                    // no-op
                })
                .catch(error => {
                    console.error("Unable to refresh session.", error)
                })
        }, 30 * 1000)
        return () => clearInterval(interval)
    }, [props.session])

    useEffect(() => {

        console.debug(new Date() + " | recognizer effect")

        if (!recognizer) {
            console.warn(new Date() + " | recognizer=null")
            return
        }

        const startContinuousRecognition = (cb = () => { }, err = () => { }) => {
            if (recognizer) {
                recognizer.startContinuousRecognitionAsync(
                    () => {
                        console.debug(new Date() + " | continuous recognition started")
                        cb()
                    },
                    error => {
                        console.error(new Date() + " | failed to start continous recognition | " + error)
                        err()
                    }
                )
            } else {
                console.warn(new Date() + " | start continuos recognition | recognizer=null")
            }
        }

        const stopContinuousRecognition = (cb = () => { }, err = () => { }) => {
            if (recognizer) {
                recognizer.stopContinuousRecognitionAsync(
                    () => {
                        console.debug(new Date() + " | continuous recognition stopped")
                        cb()
                    },
                    error => {
                        console.error(new Date() + " | failed to stop continous recognition | " + error)
                        err()
                    }
                )
            } else {
                console.warn(new Date() + " | stop continuos recognition | recognizer=null")
            }
        }

        if (mute) {
            stopContinuousRecognition(
                () => {
                    sendRecognizingToUnity(false)
                },
                () => {
                    sendRecognizingToUnity(true)
                }
            )
        } else {
            startContinuousRecognition(
                () => {
                    sendRecognizingToUnity(true)
                },
                () => {
                    sendRecognizingToUnity(false)
                }
            )
        }

        return () => {
            stopContinuousRecognition()
        }

    }, [mute, recognizer])

    useEffect(() => {
        if (error) {
            const quitWithError = async () => {
                try {
                    await unityContext.quitUnityInstance()
                } catch (error) {
                    console.error(new Date() + " | Failed to quit unity instance. | " + error);
                }
                props.setError(true)
                console.error(new Date() + " | finished with errors");
            }
            quitWithError()
        }
    }, [error])

    unityContext.on("ToggleMicrophoneNative", () => {
        console.debug(new Date() + " | toggle microphone")
        setMute(!mute)
    })

    unityContext.on("DemoFinished", async () => {
        console.debug(new Date() + " | finish demo")
        try {
            await unityContext.quitUnityInstance()
        } catch (error) {
            console.error(new Date() + " | Failed to quit unity instance. | " + error);
        }
        props.setDemoFinished(true)
        console.debug(new Date() + " | demo finished")
    })

    unityContext.on("progress", progression => {
        console.debug(new Date() + " | progression: " + progression);
        const scaled = parseInt(progression * 100)
        setProgression(scaled)
    })

    unityContext.on("loaded", () => {
        console.debug(new Date() + " | loaded");
        setLoaded(true)
    })

    unityContext.on("error", errorMessage => {
        console.error(new Date() + " | " + errorMessage)
        if (errorMessage && errorMessage.includes("warning")) {
            return
        } else {
            setError(true)
        }
    })

    useEffect(() => {

        if (!loaded) return

        const setupRecognizer = () => {
            console.debug(new Date() + " | setup recognizer")
            const speechConfig = SpeechConfig.fromSubscription(props.session.subscriptionKey, props.session.region)
            const audioConfig = AudioConfig.fromDefaultMicrophoneInput()
            const recognizer = new IntentRecognizer(speechConfig, audioConfig)
            const lm = LanguageUnderstandingModel.fromAppId(props.session.appId)
            recognizer.addAllIntents(lm)

            recognizer.recognizing = (_, event) => {
                if (event && event.result && event.result.text) {
                    const partialResult = event.result.text
                    console.debug(new Date() + " | partial result: " + partialResult)
                    sendTranscriptionToUnity(partialResult)
                }
            }

            recognizer.recognized = (_, event) => {

                // check score before passing result to unity
                const privValues = JSON.parse(event.result.privProperties.privValues[1])
                if (privValues.topScoringIntent.score < 0.8) {
                    // too low confidence
                    return
                }

                if (event && event.result && event.result.text) {
                    const finalResult = event.result.text
                    console.debug(new Date() + " | final result: " + finalResult)
                    sendTranscriptionToUnity(finalResult)
                }

                if (event && event.result && event.result.intentId) {
                    const intentId = event.result.intentId
                    console.debug(new Date() + " | recognized intent: " + intentId)
                    sendIntentToUnity(intentId)
                }

            }

            setRecognizer(recognizer)
        }

        const setupSoundsRecognition = () => {
            console.debug(new Date() + " | Enable whistle detection.")
            const classifier = ml5.soundClassifier(
                `${window.location.protocol}//${window.location.hostname}${window.location.port ? ":" + window.location.port : ""}/ai/model.json`,
                {
                    probabilityThreshold: 0.99
                },
                () => {
                    classifier.classify((error, results) => {

                        if (error) {
                            console.error(new Date() + "|" + error)
                            return
                        }

                        for (const result of results) {
                            if (result.label !== "Whistle") continue
                            if (result.confidence > 0.99) {
                                sendWhistleToUnity()
                            }
                        }

                    })
                }
            )
        }

        const acquireMicrophoneFix = async () => {
            // FIXME bug in the microsoft SDK is not requesting microphone on Safari so we're requesting it manually
            // if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            try {
                await navigator.mediaDevices.getUserMedia({ audio: true, video: false })
                console.debug(new Date() + " | microphone acquired")
                setupRecognizer()
                if (!isMobile) {
                    setupSoundsRecognition()
                }
            } catch (error) {
                console.error(new Date() + " | " + error)
                setError(true)
            }
            // } else {
            //     setupRecognizer()
            // }
        }

        sendIsMobileToUnity()
        sendFlavorToUnity(props.flavor.name)
        acquireMicrophoneFix()

    }, [loaded])


    return (
        <div className='h-full w-full fixed'>
            <Unity
                unityContext={unityContext}
                style={{
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    visibility: loaded ? 'visible' : 'hidden',
                }}
            />
            <div
                className={'h-full w-full flex flex-col text-center items-center justify-center p-2 fixed top-0 left-0' + (loaded ? ' invisible' : ' visible')}
            >
                <img
                    src={logo}
                    alt="The Digital Pets Company&trade;"
                    style={{
                        width: '320px',
                        height: 'auto'
                    }}
                />
                <div>Please wait while the application loads. This can take a couple of minutes.</div>
                <div>{progression}%</div>
            </div>
        </div>
    )
}

export default UnityComponent;
