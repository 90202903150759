import './App.css';
import Instructions from './Instructions';
import ThankYou from './ThankYou';
import UnityComponent from './UnityComponent';
import { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import Flavor from './Constants';

if (process.env.NODE_ENV === "production") {
  console.debug("Initialize Google Analytics")
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID)
  ReactGA.pageview(window.location.pathname + window.location.search)
}

const PATTERN = "https:\/\/([a-z]+)\.thedigitaldogs\.co"

function App() {

  const [demoFinished, setDemoFinished] = useState(false)
  const [error, setError] = useState(false)
  const [flavor, setFlavor] = useState(Flavor.DEFAULT)
  const [session, setSession] = useState()

  useEffect(() => {
    const url = window.location.href
    const result = url.match(PATTERN)
    if (result && result[1]) {
      switch (result[1]) {
        case Flavor.CRYPTOWALKERS.name:
          setFlavor(Flavor.CRYPTOWALKERS)
          break
        case Flavor.FLUFFY_POLAR_BEARS.name:
          setFlavor(Flavor.FLUFFY_POLAR_BEARS)
          break
        case Flavor.THE_LONG_LOST.name:
          setFlavor(Flavor.THE_LONG_LOST)
          break
        case Flavor.WEIRDO_GHOST_GANG.name:
          setFlavor(Flavor.WEIRDO_GHOST_GANG)
          break
        case Flavor.MUTTNIKS.name:
          setFlavor(Flavor.MUTTNIKS)
          break
        case Flavor.LOBSTARS.name:
          setFlavor(Flavor.LOBSTARS)
          break
        case Flavor.HABIBIZ.name:
          setFlavor(Flavor.HABIBIZ)
          break
        case Flavor.XONE.name:
          setFlavor(Flavor.XONE)
          break
        case Flavor.LITLE_LEMON_FRIENDS.name:
          setFlavor(Flavor.LITLE_LEMON_FRIENDS)
          break
        default:
          setFlavor(Flavor.DEFAULT)
          break
      }
    }
  }, [])

  if (error) {
    return (
      <div className='w-full h-full fixed flex items-center justify-center text-center p-2'>
        Something went wrong. Try to refresh the page.
      </div>
    )
  }

  if (demoFinished) {
    console.debug(Date() + " | Show thank you page.")
    return <ThankYou />
  } else if (session) {
    console.debug(Date() + " | Show demo page.")
    return <UnityComponent flavor={flavor} setDemoFinished={setDemoFinished} setError={setError} session={session} />
  } else {
    console.debug(Date() + " | Show instructions page.")
    return <Instructions flavor={flavor} setSession={setSession} />
  }

}

export default App;
