// flavor name should be the same as the subdomain
export default class Flavor {

    static DEFAULT = new Flavor("default")
    static CRYPTOWALKERS = new Flavor("cryptowalkers")
    static THE_LONG_LOST = new Flavor("thelonglost")
    static WEIRDO_GHOST_GANG = new Flavor("weirdoghost")
    static FLUFFY_POLAR_BEARS = new Flavor("polarbearsnft")
    static MUTTNIKS = new Flavor("muttniks")
    static LOBSTARS = new Flavor("thelobstars")
    static HABIBIZ = new Flavor("thehabibiz")
    static XONE = new Flavor("xone")
    static LITLE_LEMON_FRIENDS = new Flavor("llf")

    constructor(name) {
        this.name = name
    }

}
