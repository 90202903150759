import './ThankYou.css'
import logo from './digital-dogs.png'

function ThankYou() {
    return (
        <div className='backgroundThankYou h-screen w-screen container'>
            <div className='py-6 flex flex-row items-center fixed'>
                <img
                    src={logo}
                    alt="Digital Dogs"
                />
            </div>
            <div className='flex flex-col h-full justify-center items-start space-y-4'>
                <div className='text-4xl'>Thank you!</div>
                <div className='flex flex-col space-y-2'>
                    <div className='text-lg'>We want to hear all about your experience.</div>
                    <a
                        className="t-700 t-20-28 text-center"
                        style={{
                            padding: '16px 24px',
                            backgroundColor: 'rgba(185, 44, 220, 1)',
                            borderRadius: '12px',
                        }}
                        href='https://discord.gg/thedigitaldogs'

                    >
                        Join us on Discord
                    </a>
                </div>
            </div>
        </div>
    )
}

export default ThankYou
